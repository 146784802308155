import PropTypes from 'prop-types';
import React from 'react';
import DOMPurify from 'dompurify';

const urlify = (text = '', ignoreHTMLAnchors = true) => {
  // Convert URLs and email addresses into <a> tags
  const convertedText = text.replace(
    // Match URLs, email addresses, and <a> tags with URLs or emails
    /(href="|')?((?:(?:https?:\/\/)|(?:www\.)|(?:[\w-]+\.)+[\w]{2,})([^\s"']*)|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|([^\s<>"']+@[^\s<>"']+))/g,
    (substring, startsWithHref, url, urlSuffix, email, plainEmail) => {
      if (ignoreHTMLAnchors && startsWithHref) {
        // Leave existing <a> tags intact, but handle them later if needed
        return substring;
      }

      // If it's an email address or a plain email, convert to mailto:
      if (email || plainEmail) {
        const emailAddress = email || plainEmail;
        return `<a href="mailto:${emailAddress}">${emailAddress}</a>`;
      }

      // Otherwise, handle it as a URL (we add https:// if necessary)
      const fullUrl = url.startsWith('http') ? url : `https://${url}`;
      return `<a href="${fullUrl}">${url.replace(/^https?:\/\//, '')}</a>`;
    }
  );

  // Add mailto: to any email addresses in <a> tags that don't have it
  return convertedText.replace(/<a\s+href="([^"]+)">/g, (match, href) => {
    // Check if the href is an email address without mailto:
    if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(href) &&
      !/^mailto:/i.test(href)
    ) {
      href = `mailto:${href}`;
    }

    // Add target="_blank" and rel="noopener noreferrer" to non-mailto links
    const targetAndRel = /mailto:/.test(href)
      ? ''
      : ' target="_blank" rel="noopener noreferrer"';

    return `<a href="${href}"${targetAndRel}>`;
  });
};

const InjectableContent = ({ text, rightAlign, className = '' }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{
      __html: urlify(DOMPurify.sanitize(text, { ADD_ATTR: ['target', 'rel'] })),
    }}
    style={rightAlign ? { textAlign: 'right' } : {}}
  />
);
InjectableContent.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  rightAlign: PropTypes.bool,
};

export default InjectableContent;
