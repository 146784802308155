import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Progress } from 'src/components/IMUI';
import aiSurveyQuestionInsightsApi from 'src/api/AISurveyQuestionInsights';
import { useDispatch } from 'react-redux';
import { where } from 'im/api/Query';
import aiInsightsPng from 'src/static/empty_chart.png';
import aiInsightsPng2 from 'src/static/empty_responses.png';
import waiting from 'src/static/waiting.png';
import cls from './AISurveyQuestionInsights.module.css';

const AISurveyQuestionInsights = ({ question }) => {
  const [insights, setInsights] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();

  const fetchInsights = () => {
    setIsFetching(true);
    dispatch(aiSurveyQuestionInsightsApi.find(where({ id: question.id }))).then(
      (response) => {
        setInsights(response.insights);
        setIsFetching(false);
      }
    );
  };

  return (
    <Container>
      <div>
        <h3>Insights</h3>
        {isFetching && <Progress />}
      </div>
      {!insights && !isFetching && (
        <div className={cls.insightsButtonContainer}>
          <img
            src={aiInsightsPng2}
            alt="AI insights Female"
            style={{ width: 200 }}
          />
          <Button
            secondary
            className={cls.fetchInsightsButton}
            label={'Fetch AI Insights'}
            onClick={fetchInsights}
          />
          <img
            src={aiInsightsPng}
            alt="AI insights Male"
            style={{ width: 200 }}
          />
        </div>
      )}
      {insights && (
        <div className={cls.insightsContainer}>
          <img src={waiting} alt="AI insights" style={{ width: 200 }} />
          <i className={cls.insights}>{insights}</i>
        </div>
      )}
    </Container>
  );
};

AISurveyQuestionInsights.proptype = {
  question: PropTypes.object.isRequired,
};

export default AISurveyQuestionInsights;
