import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Highlight, PdfHighlighter, PdfLoader } from 'react-pdf-highlighter';
import 'react-pdf-highlighter/dist/style.css';
import cls from './Highlighter.module.css';
import { Progress } from 'src/components/IMUI';

const Highlighter = ({
  pdfUrl,
  pdfScaleValue,
  taggings,
  setSelection,
  setTagsInContext,
  setTaggingHighlightIndex,
  onDocumentLoaded,
  highlights,
}) => {
  const [loaded, setLoaded] = useState(false);
  const pdfHighlighterRef = useRef(null);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

  useEffect(() => {
    if (pdfUrl) {
      setLoaded(true); // Only load PDF once when pdfUrl is available
    }
  }, [pdfUrl]);

  const calculatePosition = useCallback((event) => {
    const page = event.target.closest('.page');
    const pageNumber = parseInt(page.getAttribute('data-page-number'));
    const boundingRect = page.getBoundingClientRect();
    const x = event.clientX - boundingRect.left; // X position within the PdfLoader
    const y = event.clientY - boundingRect.top; // Y position within the PdfLoader

    const scaleValue = boundingRect.height / 720;
    const positionX = x / scaleValue;
    const positionY = y / scaleValue;

    return { positionX, positionY, pageNumber };
  }, []);

  const calculateTagsInContext = useCallback(
    (positionX, positionY, pageNumber) => {
      return taggings.data.filter((tagging) => {
        if (pageNumber !== tagging.misc.position.pageNumber) {
          return false;
        }

        return tagging.misc.position.rects.some((rect) => {
          const originalScaleValue = rect.height / 720;
          const originalScaledX1 = rect.x1 / originalScaleValue;
          const originalScaledX2 = rect.x2 / originalScaleValue;
          const originalScaledY1 = rect.y1 / originalScaleValue;
          const originalScaledY2 = rect.y2 / originalScaleValue;

          return (
            originalScaledX1 <= positionX &&
            positionX <= originalScaledX2 &&
            originalScaledY1 <= positionY &&
            positionY <= originalScaledY2
          );
        });
      });
    },
    [taggings.data]
  );

  const onMouseOver = useCallback(
    (event) => {
      const { positionX, positionY, pageNumber } = calculatePosition(event);
      const tagsInContext = calculateTagsInContext(
        positionX,
        positionY,
        pageNumber
      );

      if (tagsInContext.length > 0) {
        event.target.classList.add('cursorPointer');
      } else {
        event.target.classList.remove('cursorPointer');
      }
    },
    [calculatePosition, calculateTagsInContext]
  );

  const onPageClick = useCallback(
    (event) => {
      const { positionX, positionY, pageNumber } = calculatePosition(event);
      const tagsInContext = calculateTagsInContext(
        positionX,
        positionY,
        pageNumber
      );
      setTagsInContext(tagsInContext);
      setTaggingHighlightIndex(null);
      setSelection({});
    },
    [
      pdfScaleValue,
      calculatePosition,
      calculateTagsInContext,
      setTagsInContext,
      setTaggingHighlightIndex,
      setSelection,
    ]
  );

  const onSelectionFinished = useCallback(
    (position, content) => {
      setSelection({
        misc: { position: position },
        content: content.text,
        type: 'taggings',
      });

      return null;
    },
    [setSelection]
  );

  useEffect(() => {
    if (!isDocumentLoaded || !pdfHighlighterRef.current?.containerNode) return;

    const containerNode = pdfHighlighterRef.current?.containerNode;
    const allPages = containerNode.querySelectorAll('.page');

    allPages.forEach((page) => {
      page.addEventListener('click', onPageClick);
      page.addEventListener('mouseover', onMouseOver);
    });

    return () => {
      allPages.forEach((page) => {
        page.removeEventListener('click', onPageClick);
      });
    };
  }, [isDocumentLoaded, onMouseOver, onPageClick]);

  const renderHighlighter = useCallback(
    (pdfDocument) => {
      if (pdfDocument) {
        return (
          <PdfHighlighter
            key={(pdfScaleValue / 100).toString()}
            ref={pdfHighlighterRef}
            pdfScaleValue={(pdfScaleValue / 100).toString()}
            pdfDocument={pdfDocument}
            onSelectionFinished={onSelectionFinished}
            highlights={highlights}
            highlightTransform={(highlight) => {
              return (
                <div
                  style={{ background: highlight.background }}
                  className={highlight.background ? 'imHighlight' : ''}
                >
                  <Highlight
                    isScrolledTo={highlight.isScrolledTo}
                    position={highlight.position}
                    comment={highlight.comment}
                  />
                </div>
              );
            }}
          />
        );
      }

      return <Progress />;
    },
    [pdfScaleValue, highlights, onSelectionFinished]
  );

  const loadPdf = useCallback(() => {
    if (!loaded) return;

    return (
      <PdfLoader url={pdfUrl} beforeLoad={<Progress />}>
        {(pdfDocument) => {
          if (!isDocumentLoaded)
            setTimeout(() => {
              setIsDocumentLoaded(true);
              onDocumentLoaded();
            }, 500);
          return renderHighlighter(pdfDocument);
        }}
      </PdfLoader>
    );
  }, [loaded, pdfUrl, isDocumentLoaded, onDocumentLoaded, renderHighlighter]);

  return <div className={cls.grandContainer}>{loadPdf()}</div>;
};

export default Highlighter;
