const esDict = process.env.NODE_ENV !== 'test' ? require('./es.json') : {};
const frDict = process.env.NODE_ENV !== 'test' ? require('./fr.json') : {};
const arDict = process.env.NODE_ENV !== 'test' ? require('./ar.json') : {};
const ptDict = process.env.NODE_ENV !== 'test' ? require('./pt.json') : {};
const ruDict = process.env.NODE_ENV !== 'test' ? require('./ru.json') : {};
const idDict = process.env.NODE_ENV !== 'test' ? require('./id.json') : {};
const itDict = process.env.NODE_ENV !== 'test' ? require('./it.json') : {};
const zhDict = process.env.NODE_ENV !== 'test' ? require('./zh.json') : {};
export const messages = {
  ...arDict,
  ...esDict,
  ...frDict,
  ...ptDict,
  ...ruDict,
  ...idDict,
  ...itDict,
  ...zhDict,
};
