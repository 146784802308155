import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { submit } from 'redux-form';

import { getRegions } from 'src/actionCreators/regionsActionCreators';
import organizationApi from 'src/api/Organization';
import organizationMembershipsApi from 'src/api/OrganizationMemberships';
import organizationsApi from 'src/api/Organizations';
import uploaderLogoApi from 'src/api/UploaderLogo';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import { Section } from 'src/components/IMUI/index';

import { where } from 'im/api/Query';

import UserOrgEditFooter from '../components/UserOrgEditFooter';
import UserOrgInputs from '../components/UserOrgInputs';
import { fromFormDataToApi, fromApiToFormData } from '../components/utils';
import { canManageOrganization, isStaff } from 'src/userStorage';

const FORM_NAME_ORG = 'userOrganizationEdit';

const formatRegions = (arr = []) =>
  arr.map(({ name, country_codes: countryCodes }) => ({ name, countryCodes }));

const UserOrganizationEdit = ({
  submitForm,
  getOrganizationMemberships,
  putOrganization,
  deleteOrganization,
  uploadOrganizationLogo,
  destroyOrganizationLogo,
  getRegionsForState,
  match,
  organization,
  defaultRegions,
  user,
  organizationMemberships,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [defaultCustomValues, setDefaultCustomValues] = useState({});

  useEffect(() => {
    setInitialValues(fromApiToFormData(organization.data));
    setDefaultCustomValues({
      ...organization.data?.meta?.defaults,
      regions: formatRegions(defaultRegions),
    });
    getRegionsForState();
    getOrganizationMemberships({ id: match.params.id });
  }, []);

  useEffect(() => {
    setInitialValues(fromApiToFormData(organization.data));
  }, [user.data.current_organization_id, organization.data]);

  const onSubmitRequest = () => {
    submitForm(FORM_NAME_ORG);
  };

  const onSubmitOrganization = async ({ ...data }) => {
    putOrganization(
      where({ id: organization.data.id }).payload({
        ...fromFormDataToApi(data),
        id: organization.data.id,
      })
    );
  };

  const onDeleteOrganization = () => {
    confirm(
      'Deleting organization',
      <p>
        Do you really want to delete {organization.title}?<br />
        Actual deletion will occur in a couple of days, so please &thinsp;
        <a href="mailto:team@impactmapper.com">contact&nbsp;us</a> if you change
        your mind.
      </p>,
      true
    ).then(
      () => deleteOrganization({ id: organization.data.id }),
      () => void 0
    );
  };

  const onLogoChange = (newLogo) => {
    if (newLogo[0] === organization.data.logo_url) return;
    if (!newLogo[0] && !!organization.data.logo_url)
      destroyOrganizationLogo({
        id: organization.data.logo.id,
      });
    if (newLogo[0])
      uploadOrganizationLogo(
        where().actionMeta({ noSync: true }).payloadMultipart(
          {
            organization_id: organization.data.id,
            asset: newLogo[0],
          },
          'logo'
        )
      );
  };

  const isOrgCreator =
    organizationMemberships?.data?.[0]?.creator?.id === user.data.id;

  return (
    <div>
      <Section collapsed>
        <UserOrgInputs
          onLogoChange={onLogoChange}
          canEdit={canManageOrganization()}
          form={FORM_NAME_ORG}
          onSubmit={onSubmitOrganization}
          initialValues={initialValues}
          defaultCustomValues={defaultCustomValues}
        />
      </Section>
      <UserOrgEditFooter
        canRemove={
          !organization.meta?.toBeDeleted && (isStaff() || isOrgCreator)
        }
        isCurrentOrg={user.data.current_organization_id === match.params.id}
        onDeleteOrganization={onDeleteOrganization}
        onSubmitRequest={onSubmitRequest}
      />
    </div>
  );
};

UserOrganizationEdit.propTypes = {
  submitForm: PropTypes.func.isRequired,
  getOrganizationMemberships: PropTypes.func.isRequired,
  putOrganization: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  uploadOrganizationLogo: PropTypes.func.isRequired,
  destroyOrganizationLogo: PropTypes.func.isRequired,
  getRegionsForState: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match,
  organization: PropTypes.object,
  defaultRegions: PropTypes.array,
  user: PropTypes.object,
  organizationMemberships: PropTypes.object,
};

UserOrganizationEdit.defaultProps = { organization: {} };

export default connect(
  (state) => ({
    defaultRegions: state.defaultRegions,
    user: state.user,
    organization: state.organization,
    organizationMemberships: state.organizationMemberships,
  }),
  {
    submitForm: submit,
    uploadOrganizationLogo: uploaderLogoApi.uploadOrganizationLogo,
    destroyOrganizationLogo: uploaderLogoApi.destroyOrganizationLogo,
    getRegionsForState: getRegions,
    getOrganizationMemberships: organizationMembershipsApi.findAll,
    putOrganization: organizationApi.put,
    deleteOrganization: organizationsApi.destroy,
  }
)(UserOrganizationEdit);
