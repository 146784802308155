import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  getReport,
  updateReport,
} from 'src/actionCreators/reportActionCreators';
import {
  isNumeric,
  getNumberFromText,
} from 'src/components/TaggingType/taggingTypeHelper';
import taggingsApi from 'src/api/Taggings';
import { where } from 'im/api/Query';
import history from 'src/historyInstance';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import { Icon } from 'im/ui';
import { Card, CardFooter, Container, Section } from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import { COLORING_STRATEGIES } from 'src/pages/App/Analysis/TagEditor/components/coloringStrategy';
import { uniq } from 'src/utils/array';
import { HIGHLIGHT_COLOURS } from './components/Settings';
import EditorMinimap from 'src/pages/App/Analysis/TagEditor/components/EditorMinimap';
import QuotesList from 'src/pages/App/Analysis/TagEditor/components/Quotes';
import TagEditorSidebar2 from 'src/pages/App/Analysis/TagEditor/components/TagEditorSidebar2';
import TaggingDetailsSelector from 'src/pages/App/Analysis/TagEditor/components/TaggingDetailsSelector';
import TaggingInContext from 'src/pages/App/Analysis/TagEditor/components/TaggingInContext';
import Highlighter from './components/Highlighter';
import cls from 'src/pages/App/Analysis/TagEditor/TagEditor2.module.css';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';

const cx = classNames.bind(cls);
const MIN_ZOOM = 50;
const MAX_ZOOM = 150;
const DEFAULT_ZOOM = 100;

const TagEditor2 = ({
  match,
  organizationCurrent,
  project,
  report,
  taggings,
  user,
  createTagging,
  updateTagging,
  deleteTagging,
  showPopupNotification,
  getCurrentReport,
  updateCurrentReport,
  getTaggings,
}) => {
  const [coloringStrategy, setColoringStrategy] = useState(
    COLORING_STRATEGIES.SINGLE_COLOR_SHOW_OVERLAPS_SHOW_HOVER
  );
  const [selection, setSelection] = useState({});
  const [tagsInContext, setTagsInContext] = useState([]);
  const [taggingHighlightIndex, setTaggingHighlightIndex] = useState(null);
  const [taggingDetailsEditing, setTaggingDetailsEditing] = useState(null);
  const [marks, setMarks] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [pdfScaleValue, setPdfScaleValue] = useState(DEFAULT_ZOOM);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  const hasSelectedTaggings = tagsInContext?.length > 0;
  const hasSelectedHighlight = selection?.content;

  const doGetTaggings = useCallback(() => {
    getTaggings(
      where({ taggable_id: report.reportUid })
        .include('tag', 'tag.tag_categories')
        .filter('project_id_eq', project.uid)
        .filter('scope', 'content')
        .paginate({ size: 3000 })
        .pending('init')
    );
  }, [report, project, getTaggings]);

  const onZoom = (newPdfScaleValue) => {
    const pdfViewer = document.querySelector('.pdfViewer');
    const scrollingContainer = pdfViewer?.parentElement;
    const currentScrollPosition = scrollingContainer?.scrollTop || 0;

    setPdfScaleValue((prevPdfScaleValue) => {
      setLastScrollPosition(
        (currentScrollPosition / prevPdfScaleValue) * newPdfScaleValue
      );
      return newPdfScaleValue;
    });
  };

  useEffect(() => {
    const pdfViewer = document.querySelector('.pdfViewer');

    if (!pdfViewer) {
      return;
    }

    const scrollingContainer = pdfViewer.parentElement;
    scrollingContainer?.scrollTo({
      top: lastScrollPosition,
      behavior: 'instant',
    });
  }, [lastScrollPosition]);

  const calculateTaggingPosition = useCallback(
    (tagging, absolute = true) => {
      const pdfViewer = document.querySelector('.pdfViewer');

      if (!pdfViewer || !isDocumentLoaded) {
        return { top: 0, left: 0 };
      }

      const pageNumber = tagging.misc.position.pageNumber;
      const pdfPage = pdfViewer.querySelector(`.page:nth-child(${pageNumber})`);
      const boundingRect = pdfPage.getBoundingClientRect();

      const currentScaleValue = boundingRect.height / 720;
      const taggingBoundingRect = tagging.misc?.position.boundingRect;
      const taggingScaleValue = taggingBoundingRect.height / 720;

      const taggingPositionY1 =
        (taggingBoundingRect.y1 / taggingScaleValue) * currentScaleValue;
      const taggingPositionY2 =
        (taggingBoundingRect.y2 / taggingScaleValue) * currentScaleValue;

      const left =
        ((taggingBoundingRect.x2 + taggingBoundingRect.x1) /
          2 /
          2 /
          taggingScaleValue) *
        currentScaleValue;

      if (absolute) {
        const topPage = boundingRect.top + pdfViewer.parentElement.scrollTop;
        return {
          top: taggingPositionY1 + topPage,
          left: left,
          bottom: taggingPositionY2 - topPage,
        };
      }

      const yOffset =
        pdfViewer.parentElement.getBoundingClientRect().top - boundingRect.top;
      return {
        top: taggingPositionY1 - yOffset,
        left: left,
        bottom: taggingPositionY2 - yOffset,
      };
    },
    [isDocumentLoaded]
  );

  const scrollToTagging = (tagging, behavior = 'smooth') => {
    const pdfViewer = document.querySelector('.pdfViewer');

    if (!pdfViewer || !isDocumentLoaded) {
      return;
    }

    const pageNumber = tagging.misc.position.pageNumber;
    const pdfPage = pdfViewer.querySelector(`.page:nth-child(${pageNumber})`);
    const boundingRect = pdfPage.getBoundingClientRect();

    const { top } = calculateTaggingPosition(tagging);

    const centeredPositionY = top - boundingRect.height / 2;

    pdfViewer.parentElement.scrollTo({
      top: centeredPositionY,
      behavior: behavior,
    });
  };

  const onTaggingHighlight = useCallback(
    (tag) => {
      const matchingTaggings = taggings.data.filter(
        (tagging) => tagging.tag.id === tag.id
      );

      if (matchingTaggings.length === 0) {
        return;
      }

      if (
        taggingHighlightIndex === null ||
        taggingHighlightIndex + 1 >= matchingTaggings.length
      ) {
        scrollToTagging(matchingTaggings[0]);
        setTaggingHighlightIndex(0);
        setTagsInContext([matchingTaggings[0]]);
      } else {
        scrollToTagging(matchingTaggings[taggingHighlightIndex + 1]);
        setTaggingHighlightIndex(taggingHighlightIndex + 1);
        setTagsInContext([matchingTaggings[taggingHighlightIndex + 1]]);
      }
    },
    [taggingHighlightIndex, taggings.data]
  );

  const onFinishTagging = useCallback(() => {
    history.length > 1
      ? history.goBack()
      : history.replace(`/analysis/${match.params.projectId}/reports/`);
  }, [match.params.projectId]);

  const onDoneEditing = useCallback(() => {
    updateCurrentReport(match.params.projectId, match.params.reportId, {
      project_report: { tagging_completed: true },
    }).then(onFinishTagging);
  }, [
    updateCurrentReport,
    match.params.projectId,
    match.params.reportId,
    onFinishTagging,
  ]);
  const onUndoneEditing = useCallback(() => {
    updateCurrentReport(match.params.projectId, match.params.reportId, {
      project_report: { tagging_completed: false },
    }).then(onFinishTagging);
  }, [
    updateCurrentReport,
    match.params.projectId,
    match.params.reportId,
    onFinishTagging,
  ]);

  useEffect(() => {
    getCurrentReport(match.params.projectId, match.params.reportId);
  }, [getCurrentReport, match.params.projectId, match.params.reportId]);

  useEffect(() => {
    getCurrentReport(match.params.projectId, match.params.reportId);
  }, [getCurrentReport, match.params.projectId, match.params.reportId]);

  useEffect(() => {
    if (report && report.reportUid) {
      doGetTaggings();
    }
  }, [report, doGetTaggings]);

  const colorMap = useMemo(() => {
    if (coloringStrategy === 'SINGLE_COLOR_SHOW_OVERLAPS_SHOW_HOVER') {
      return null;
    }

    return uniq(taggings.data.map((t) => t.tag.tag_categories?.[0]?.id))
      .sort()
      .reduce((acc, key, index) => {
        acc[key] = HIGHLIGHT_COLOURS[index]; // Assign a default color if colors array is shorter
        return acc;
      }, {});
  }, [taggings.data, coloringStrategy]);

  useEffect(() => {
    if (taggings.data.length !== highlights.length || tagsInContext) {
      const taggingIdsInContext = tagsInContext.map((tagging) => tagging?.id);

      setHighlights(
        taggings.data.map((tagging) => {
          const isFocused = tagsInContext.some(
            (tagInContext) => tagInContext.id === tagging.id
          );
          const background = isFocused
            ? '#fff59d' // highlight yellow if focused
            : colorMap
            ? colorMap[tagging.tag.tag_categories?.[0]?.id] // color by tag category
            : HIGHLIGHT_COLOURS[0]; // initial color;

          return {
            background: background,
            isScrolledTo: taggingIdsInContext.includes(tagging.id),
            content: { text: tagging?.content },
            comment: { emoji: '', text: tagging.tag?.title },
            ...(tagging?.misc || {}),
          };
        })
      );
    }
  }, [
    taggings,
    highlights.length,
    tagsInContext,
    taggingHighlightIndex,
    coloringStrategy,
    colorMap,
  ]);

  const addTagging = (tagging) => {
    const attributes = isNumeric(selection.content)
      ? {
          ...selection,
          ...getNumberFromText(selection.content),
        }
      : selection;

    createTagging(
      where().payload({
        attributes: attributes,
        relationships: [
          { relName: 'tag', type: 'tags', id: tagging.tag?.id },
          {
            relName: 'taggable',
            type: 'reports',
            id: report.reportUid,
          },
          { relName: 'user', type: 'users', id: user.data.id },
          {
            relName: 'organization',
            type: 'organizations',
            id: organizationCurrent.data.id,
          },
          {
            relName: 'project',
            type: 'projects',
            id: project.uid,
          },
        ],
      })
    ).then(doGetTaggings);
  };

  const onTaggingRemove = (tagging) =>
    tagging
      ? deleteTagging(where({ id: tagging.id })).then(() => {
          setTaggingDetailsEditing(null);
          setTagsInContext([]);
          doGetTaggings();
        })
      : showPopupNotification({
          title: 'Removing taggings',
          message:
            'You have to first find in text the tagging you want to remove',
          level: 'warning',
          autoDismiss: 10,
        });

  const onDetailsClose = () => {
    setTaggingDetailsEditing(null);
    setTagsInContext([]);
  };

  const handleEditTagging = (tagging, taggingDetails) => {
    updateTagging(
      where({ id: tagging.id }).payload({
        attributes: {
          quantity: null,
          amount: null,
          currency: null,
          year: null,
          month: null,
          day: null,
          location: null,
          ...taggingDetails,
        },
        relationships: [
          {
            relName: 'taggable',
            type: 'reports',
            id: report.reportUid,
          },
          { relName: 'project', type: 'projects', id: project.uid },
        ],
      })
    ).then(() => {
      onDetailsClose();
      doGetTaggings();
    });
  };

  const calculateDetailsEditingPosition = () => {
    const pdfViewer = document.querySelector('.pdfViewer');

    if (!pdfViewer || !taggingDetailsEditing) {
      return { top: 0, left: 0 };
    }

    const { bottom, left } = calculateTaggingPosition(
      taggingDetailsEditing,
      false
    );

    return {
      top: bottom,
      left: left,
    };
  };

  useEffect(() => {
    const pdfViewer = document.querySelector('.pdfViewer');
    if (!pdfViewer || !isDocumentLoaded) {
      return;
    }

    const markings = taggings.data.map((tagging) => {
      const isFocused = tagsInContext.some(
        (tagInContext) => tagInContext.id === tagging.id
      );
      const color = isFocused
        ? '#fff59d' // highlight yellow if focused
        : colorMap
        ? colorMap[tagging.tag.tag_categories?.[0]?.id] // color by tag category
        : HIGHLIGHT_COLOURS[0]; // initial color;

      const { top } = calculateTaggingPosition(tagging);
      return {
        offsetTop: top - 200,
        color: color,
      };
    });

    setMarks(markings);
  }, [
    calculateTaggingPosition,
    colorMap,
    taggings.data,
    tagsInContext,
    isDocumentLoaded,
  ]);

  const handleScrollTo = useCallback(
    (offsetTop) => {
      const pdfViewer = document.querySelector('.pdfViewer');
      if (!pdfViewer || !isDocumentLoaded) {
        return;
      }
      pdfViewer?.parentElement?.scrollTo(0, offsetTop);
    },
    [isDocumentLoaded]
  );

  const onDocumentLoaded = () => {
    setIsDocumentLoaded(true);
  };

  const onChangeColoringStrategy = (payload) => {
    setColoringStrategy(payload?.value);
  };

  const editorScrollHeight = () => {
    const pdfViewer = document.querySelector('.pdfViewer');
    if (!pdfViewer || !isDocumentLoaded) {
      return 0;
    }
    return pdfViewer.parentElement.scrollHeight ?? 0;
  };

  const onTaggingEdit = (tagging) => {
    scrollToTagging(tagging, 'instant');
    setTaggingDetailsEditing(tagging);
  };

  return (
    <Page noPadding title={report.name} back="0">
      <Section horizontal grow className={cx(cls.tagEditorCardWrapper)}>
        <FlexColumn
          style={{ position: 'relative', paddingRight: 16, height: '100%' }}
          size={2 / 3}
        >
          <Card className={cls.tagEditorCard} noPadding grow>
            <Container grow className={cx(cls.editorArea)}>
              <div className={cls.editorWrapper}>
                <Highlighter
                  key={report.id}
                  taggings={taggings}
                  pdfUrl={report.original_asset_url}
                  pdfScaleValue={pdfScaleValue}
                  onDocumentLoaded={onDocumentLoaded}
                  setSelection={setSelection}
                  setTagsInContext={setTagsInContext}
                  setTaggingHighlightIndex={setTaggingHighlightIndex}
                  highlights={highlights}
                />
                {taggingDetailsEditing?.id && (
                  <TaggingDetailsSelector
                    open
                    isEdit={true}
                    position={calculateDetailsEditingPosition()}
                    onSubmit={taggingDetailsEditing?.id && handleEditTagging}
                    tagging={taggingDetailsEditing}
                    onRequestClose={onDetailsClose}
                    onTaggingRemove={onTaggingRemove}
                  />
                )}
              </div>
              <EditorMinimap
                editorScrollHeight={editorScrollHeight()}
                onScrollTo={(pos) => handleScrollTo(pos, false)}
                marks={marks}
              />
            </Container>

            {hasSelectedTaggings && (
              <section className={cls.tagsInContext}>
                <Container horizontal style={{ gap: 3 }}>
                  <label style={{ lineHeight: '18px' }}>Tagged with:</label>
                  {tagsInContext.map((t) => (
                    <TaggingInContext
                      key={t.id}
                      tagging={t}
                      onTaggingEdit={onTaggingEdit}
                      onTaggingRemove={onTaggingRemove}
                    />
                  ))}
                </Container>
              </section>
            )}
            {hasSelectedHighlight && (
              <section className={cls.tagsInContext}>
                <small>
                  <label style={{ fontStyle: 'normal' }}>
                    Selected text:&nbsp;
                  </label>
                  {selection.content?.length > 100
                    ? `${String(selection.content).substring(0, 100)}...`
                    : selection.content}
                </small>
              </section>
            )}

            <CardFooter key={report.reportUid} className={cls.tagEditorFooter}>
              <Container horizontal spaceBetween>
                <Container
                  horizontal
                  style={{ display: 'flex', gap: 8, padding: 8 }}
                >
                  <Icon
                    name="arrow-left"
                    tip="Zoom Out"
                    disabled={pdfScaleValue <= MIN_ZOOM}
                    style={{ color: '#52cccc', opacity: 1 }}
                    onClick={() => onZoom(pdfScaleValue - 10)}
                  />
                  <small>{pdfScaleValue}%</small>
                  <Icon
                    name="arrow-right"
                    tip="Zoom In"
                    disabled={pdfScaleValue >= MAX_ZOOM}
                    style={{ color: '#52cccc', opacity: 1 }}
                    onClick={() => onZoom(pdfScaleValue + 10)}
                  />
                </Container>
                {report.reportUid && (
                  <span>
                    <QuotesList
                      taggableType="reports"
                      type="footer"
                      taggableUid={report.reportUid}
                      selectedText={selection.content}
                      setSelection={setSelection}
                    />
                  </span>
                )}
              </Container>
            </CardFooter>
          </Card>
        </FlexColumn>

        <FlexColumn
          style={{
            padding: 0,
            position: 'relative',
            height: '100%',
            minWidth: 286,
          }}
          size={1 / 3}
        >
          <TagEditorSidebar2
            project={project}
            report={report}
            taggings={taggings}
            tagsInContext={tagsInContext}
            selectedText={selection.content}
            addTagging={addTagging}
            removeTagging={onTaggingRemove}
            changeColoringStrategy={onChangeColoringStrategy}
            onTaggingHighlight={onTaggingHighlight}
            onDoneEditing={
              report.tagging_completed_at ? undefined : onDoneEditing
            }
            onUndoneEditing={
              report.tagging_completed_at ? onUndoneEditing : undefined
            }
            coloringStrategy={coloringStrategy}
          />
        </FlexColumn>
      </Section>
    </Page>
  );
};

TagEditor2.propTypes = {
  match: ReactRouterPropTypes.match,
  organizationCurrent: PropTypes.object,
  project: PropTypes.object,
  report: PropTypes.object,
  taggings: PropTypes.object,
  user: PropTypes.object,
  createTagging: PropTypes.func.isRequired,
  updateTagging: PropTypes.func.isRequired,
  deleteTagging: PropTypes.func.isRequired,
  getCurrentReport: PropTypes.func,
  updateCurrentReport: PropTypes.func,
  getTaggings: PropTypes.func,
  showPopupNotification: PropTypes.func.isRequired,
};

const connection = connect(
  (state) => ({
    organizationCurrent: state.organizationCurrent,
    project: state.project,
    report: state.report,
    taggings: state.taggings,
    user: state.user,
  }),
  {
    createTagging: taggingsApi.create,
    deleteTagging: taggingsApi.destroy,
    updateTagging: taggingsApi.put,
    getCurrentReport: getReport,
    updateCurrentReport: updateReport,
    getTaggings: taggingsApi.findAllPerTaggable,
    showPopupNotification: showNotification,
  }
);
export default connection(TagEditor2);
