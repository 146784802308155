export const OPERATORS = {
  NOT_EMPTY: { value: 'NOT_EMPTY', label: 'has answer?', showValue: false },
  EMPTY: { value: 'EMPTY', label: 'is not answered?', showValue: false },
  IS_EQUAL: { value: 'IS_EQUAL', label: 'is equal', showValue: true },
  IS_NOT_EQUAL: {
    value: 'IS_NOT_EQUAL',
    label: 'is not equal',
    showValue: true,
  },
  IS_GREATER_THAN: {
    value: 'IS_GREATER_THAN',
    label: 'is greater than',
    showValue: true,
  },
  IS_LESS_THAN: {
    value: 'IS_LESS_THAN',
    label: 'is less than',
    showValue: true,
  },
  CONTAINS: { value: 'CONTAINS', label: 'contains', showValue: true },
  INCLUDES: { value: 'INCLUDES', label: 'has selected', showValue: true },
  NOT_INCLUDES: {
    value: 'NOT_INCLUDES',
    label: 'has not selected',
    showValue: true,
  },
};

export const validateCondition = (question, answer, condition) => {
  if (question.type.match(/multipleChoice|singleChoice/)) {
    let option;
    switch (condition.operator) {
      case OPERATORS.INCLUDES.value:
        option = question.options.find((o) => o.id === condition.value);
        return (
          option && answer.value && answer.value.indexOf(option.title) !== -1
        );
      case OPERATORS.NOT_INCLUDES.value:
        option = question.options.find((o) => o.id === condition.value);
        return (
          option && (!answer.value || answer.value.indexOf(option.title) === -1)
        );
      case OPERATORS.NOT_EMPTY.value:
        return answer.value && !!answer.value.length;
      case OPERATORS.EMPTY.value:
        return !answer.value || !answer.value.length;
      default:
        break;
    }
  } else if (question.type.match(/shortText|longText/)) {
    switch (condition.operator) {
      case OPERATORS.IS_EQUAL.value:
        return answer.value && answer.value === condition.value;
      case OPERATORS.IS_NOT_EQUAL.value:
        return answer.value && answer.value !== condition.value;
      case OPERATORS.CONTAINS.value:
        return answer.value && answer.value.indexOf(condition.value) !== -1;
      case OPERATORS.NOT_EMPTY.value:
        return answer.value && !!`${answer.value}`.trim();
      case OPERATORS.EMPTY.value:
        return !answer.value || !answer.value.trim();
      default:
        break;
    }
  } else if (question.type.match(/financial|numerical/)) {
    switch (condition.operator) {
      case OPERATORS.IS_GREATER_THAN.value:
        return (
          (answer.value || answer.value === 0) &&
          +answer.value > +condition.value
        );
      case OPERATORS.IS_LESS_THAN.value:
        return (
          (answer.value || answer.value === 0) && answer.value < condition.value
        );
      case OPERATORS.IS_EQUAL.value:
        return (
          (answer.value || answer.value === 0) &&
          answer.value === condition.value
        );
      case OPERATORS.IS_NOT_EQUAL.value:
        return (
          (answer.value || answer.value === 0) &&
          answer.value !== condition.value
        );
      case OPERATORS.NOT_EMPTY.value:
        return answer.value !== null && answer.value !== undefined;
      case OPERATORS.EMPTY.value:
        return answer.value === null;
      default:
        break;
    }
  }
  return false;
};
// returns true, false or null if no valid conditions present
export const conditionsCheck = (question, survey, answers) => {
  const questions = survey.questions;
  const conditions = question.conditional_logic;
  const baseOperator =
    (question.settings && question.settings.clBaseOperator) || 'OR';
  const hasConditions = conditions && !!conditions.length;
  let validCount = 0;
  let passedCount = 0;

  if (hasConditions) {
    for (let i = 0; i < conditions.length; i += 1) {
      const condition = conditions[i];
      if (
        condition.question_id &&
        condition.operator &&
        (!OPERATORS[condition.operator].showValue || condition.value)
      ) {
        const refQuestion = questions.find(
          (q) => q.id === condition.question_id
        );
        // TODO what to do when there are no answers (preview)?
        if (!refQuestion || !answers) return false;
        validCount += 1;
        const refAnswer = answers.find((a) => a.id === refQuestion.id);

        const hasInheritedConditions =
          refQuestion.conditional_logic &&
          !!refQuestion.conditional_logic.length;

        const passesDirectConditions =
          refAnswer && validateCondition(refQuestion, refAnswer, condition);
        const passesInheritedConditions = hasInheritedConditions
          ? conditionsCheck(refQuestion, survey, answers)
          : true;

        if (passesDirectConditions && passesInheritedConditions) {
          passedCount += 1;
          if (baseOperator === 'OR') {
            return true;
          }
        }
      }
    }

    if (
      baseOperator === 'AND' &&
      validCount > 0 &&
      passedCount === validCount
    ) {
      return true;
    }
  }

  if (validCount === 0 || !hasConditions) {
    return null;
  }

  return false;
};
