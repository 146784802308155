import clone from 'ramda/src/clone';
import equals from 'ramda/src/equals';
import path from 'ramda/src/path';

export const uniq = (arr) => {
  return arr.filter((elem, pos, currentArray) => {
    return currentArray.indexOf(elem) === pos;
  });
};

export const arrToObj = (arr) =>
  (arr || []).reduce((acc, key) => ({ ...acc, [key]: '' }), {});

const transformValue = (value, transform) => {
  if (!transform && typeof value === 'string') {
    return value.toLowerCase();
  }
  return !transform ? value : transform(value);
};

// sortWith([sortDirection ? ascend(prop(sortBy)) : descend(prop(sortBy))]);
export function sortBy(items, sortKey, sortDirection, transform) {
  const sortKeys = sortKey?.split('.') || [];
  let result;

  return items.sort((a, b) => {
    const aValue = transformValue(path(sortKeys, a), transform);
    const bValue = transformValue(path(sortKeys, b), transform);

    if (!aValue) {
      return 1;
    }
    if (!bValue) {
      return -1;
    }
    if (aValue === bValue) {
      return 0;
    }

    result = aValue < bValue ? -1 : 1;
    return sortDirection ? result : result * -1;
  });
}

export const shallowIsEqual = (arr, arrOther, skipItemsOrder) => {
  if (arr.length !== arrOther.length) {
    return false;
  }

  const otherCopy = clone(arrOther);
  const compareShallow = (a, b) => equals(a, b);

  const compareSkipOrder = (el) => {
    const index = otherCopy.findIndex(({ id }) => id === el.id);
    if (index < 0) {
      return false;
    }

    const areTheSame = compareShallow(el, otherCopy[index]);
    otherCopy.splice(index, 1);
    return areTheSame;
  };

  /* eslint-disable */
  for (let i = 0; i < arr.length; i++) {
    if (skipItemsOrder && compareSkipOrder(arr[i]) === false) return false;
    if (!skipItemsOrder && compareShallow(arr[i], arrOther[i]) === false)
      return false;
  }
  /* eslint-enable */

  return skipItemsOrder ? otherCopy.length === 0 : true;
};
