import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/v3/ai/survey_question_insights',
  type: 'ai_survey_question_insights',
  api: {
    allow: ['find'],
  },
};

export const AISurveyQuestionInsights = new Resource(
  apiConfig,
  'ai_survey_question_insight'
);
reducerRegistry.register(AISurveyQuestionInsights);

export default AISurveyQuestionInsights.api;
